html,
body {
  height: 100%;
  background-color: rgb(249, 250, 251);
}

#root {
  height: 100%;
}

html {
  box-sizing: border-box;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}
